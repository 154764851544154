import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchLegalEntityStore = defineStore('searchLegalEntityStore', {
  state: () => ({
    LegalEntity_uid: null as string | null,
    LegalEntity_agisId: null as number | null,
    LegalEntity_egid: null as number | null,
    LegalEntity_status: null as number | null,
    useTerms: true,
    items: [
      { key: 'LegalEntity_uid', operation: '~' },
      { key: 'LegalEntity_agisId', operation: ':' },
      { key: 'LegalEntity_egid', operation: ':' },
      { key: 'LegalEntity_status', operation: ':', itemType: 'number' }
    ] as TermItemDefinition[]
  })
})
